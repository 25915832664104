<template>
    <v-chip
        class="table-label"
        small
        label
        dark
        color="primary"
    >
        {{ $t(text) }}
    </v-chip>
</template>

<script>
export default {
    name: "CommonDocumentChip",
    props: {
        documentType: {
            type: String,
            default: null
        }
    },
    computed: {
        text() {
            switch (this.documentType) {
                case "IncomingDocument": 
                case "ProtocolDocument":
                    return "Внешний";
                case "OutgoingDocument": 
                case "NPADocument": 
                    return "Общий";
                default: return "Внешний";
            }
        }
    }
}
</script>